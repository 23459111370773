import React from "react";
import Header from "./Header";

import "./AboutMe.css"

const AboutMe = () => {
return (
<div className="lect_notes_main_cont">
  <Header active="about_me" />
  <div className="lect_notes_main_cont_inner">
  <div className="section aboutme-section first-text-about-me">
      <p className="aboutme-text">
        Born in Dnipro, Ukraine 🇺🇦. Currently living in Bures-sur-Yvette, France
        🇫🇷. Programming experience since November 2019. Currently studying
        Mathematics and Computer Science at Paris-Saclay University.
      </p>
    </div>

    <div className="section aboutme-section">
      <h2 className="section-title aboutme-section-title">Education</h2>
      <p className="aboutme-text">
        <strong>Université Paris-Saclay</strong> – Dual Degree in Mathematics and Computer Science (Sept 2023 – May 2026)
      </p>
      <p className="aboutme-text">
        GPA: 17.3/20 (Top 5 of 57 students) | Honors: FMJH Scholarship
      </p>
      <p className="aboutme-text">
        Relevant Coursework: Calculus, Analysis, Linear Algebra, Abstract Algebra &amp; Group Theory, Probability &amp; Statistics, Algorithmics &amp; Data Structures, Functional Programming
      </p>
    </div>

    <div className="section aboutme-section">
      <h2 className="section-title aboutme-section-title">Technical Skills</h2>
      <p className="aboutme-text"><strong>Programming Languages:</strong> Python, Rust, OCaml, C++, C#, JavaScript</p>
      <p className="aboutme-text"><strong>Web Development:</strong> ASP.NET Core, Angular, React, MySQL</p>
      <p className="aboutme-text"><strong>Data Science:</strong> Pandas, Matplotlib, NumPy, scikit-learn</p>
      <p className="aboutme-text"><strong>Other Tools:</strong> Requests, Selenium, SQL</p>
      <p className="aboutme-text"><strong>Version Control:</strong> Git, GitHub, GitLab</p>
    </div>

    <div className="section aboutme-section">
      <h2 className="section-title aboutme-section-title">Experience</h2>
      <p className="aboutme-text">
        <strong>DevDJsUa, Software Engineer</strong> (June 2021 – December 2021) – Built scalable full-stack applications and interactive tools for clients,
        specializing in custom solutions including CRM systems and user-facing bots.
      </p>
      <p className="aboutme-text">
        <strong>Freelance, Software Engineer</strong> (January 2021 – February 2022) – Developed full-stack web applications using JavaScript, C#, React, and Angular.
        Designed and implemented multiple Telegram bots for automation and user interaction.
      </p>
    </div>

    <div className="section aboutme-section">
      <h2 className="section-title aboutme-section-title">Projects</h2>
      <div className="projects-container">
        <div className="project-item">
          <div className="project-info">
            <strong>Full-Stack CRM for Scout Organization:</strong> Built a functional CRM using C#, ASP.NET Core, Entity Framework, MySQL, and Angular.
          </div>
          <a className="project-link" href="https://github.com/DobbiKov/sdSite" target="_blank" rel="noopener noreferrer">
            View on GitHub
          </a>
        </div>
        <div className="project-item">
          <div className="project-info">
            <strong>Telegram Bot for Track Sorting:</strong> Designed a bot to categorize tracks by tonality using Python.
          </div>
          <a className="project-link" href="https://github.com/DevDJsUA/key-sort-app" target="_blank" rel="noopener noreferrer">
            View on GitHub
          </a>
        </div>
        <div className="project-item">
          <div className="project-info">
            <strong>GTA Online GameMode:</strong> Developed a 30,000+ line game mode in Pawn, incorporating CRM functionalities, SQL, and algorithms.
          </div>
          <a className="project-link" href="https://github.com/DobbiKov/crmpmode" target="_blank" rel="noopener noreferrer">
            View on GitHub
          </a>
        </div>
        <div className="project-item">
          <div className="project-info">
            <strong>University Timetable Bot:</strong> Created a bot to parse PDF files and assist users with scheduling using Python.
          </div>
          <a className="project-link" href="https://github.com/DobbiKov/paris-saclay-bot" target="_blank" rel="noopener noreferrer">
            View on GitHub
          </a>
        </div>
        <div className="project-item">
          <div className="project-info">
            <strong>Neural Networks in Rust:</strong> Built neural networks from scratch to explore machine learning concepts.
          </div>
          <a className="project-link" href="https://github.com/DobbiKov/neural-network-rust" target="_blank" rel="noopener noreferrer">
            View on GitHub
          </a>
        </div>
        <div className="project-item">
          <div className="project-info">
            <strong>Image Recognition Project:</strong> Conducted image recognition experiments using Python, Pandas, Matplotlib, NumPy, and scikit-learn.
          </div>
        </div>
        <div className="project-item">
          <div className="project-info">
            <strong>Web Scraping Scripts:</strong> Automated data collection processes with Python, Selenium, and Requests.
          </div>
        </div>
      </div>
    </div>

    <div className="section aboutme-section">
      <h2 className="section-title aboutme-section-title">Languages</h2>
      <p className="aboutme-text"><strong>Fluent:</strong> English🇬🇧, French🇫🇷</p>
      <p className="aboutme-text"><strong>Native:</strong> Ukrainian🇺🇦, Russian🏴‍☠️</p>
    </div>

    <div className="section aboutme-section">
      <h2 className="section-title aboutme-section-title">Interests &amp; Extracurricular Activities</h2>
      <p className="aboutme-text">
        <strong>Scouting Leader:</strong> Active scout since age 12, organizing and participating in international events.
      </p>
      <p className="aboutme-text">
        <strong>DJing Enthusiast:</strong> Enjoys creating music and performing as a DJ during free time.
      </p>
    </div>
  </div>
</div>
);
};

export default AboutMe;